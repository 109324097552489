<template>
  <div class="break-wrapper">
    <div class="break" />
  </div>
</template>

<script>
export default {
  name: "Break",
};
</script>

<style scoped>
.break {
  height: 2px;
  width: 85vw;
  margin-top: 90px;
  margin-bottom: 90px;
  background-color: #e7bd74;
  border-radius: 5px;
}
.break-wrapper {
  display: flex;
  justify-content: center;
}
@media (max-width: 860px) {
  .break {
    margin-top: 50px;
    margin-bottom: 50px;
  }
} ;
</style>
