<template>
  <h1 class="iconography-header">Gallery</h1>
  <div class="grid">
    <img src="../assets/gallery/katya1.jpg" alt="" />
    <img src="../assets/gallery/katya2.jpg" alt="" />
    <img src="../assets/gallery/katya3.png" alt="" />
    <img src="../assets/gallery/katya4.jpg" alt="" />
    <img src="../assets/gallery/katya5.jpg" alt="" />
    <img src="../assets/gallery/katya6.jpg" alt="" />
    <img src="../assets/gallery/katya7.png" alt="" />
    <img src="../assets/gallery/katya8.jpg" alt="" />
    <img src="../assets/gallery/katya9.png" alt="" />
  </div>
</template>

<script>
export default {
  name: "Gallery",
};
</script>

<style>
.gallery-wrapper > img:nth-child(odd) {
  animation: fade-in 1s;
}
.gallery-wrapper > img:nth-child(even) {
  animation: fade-in 2s;
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media (max-width: 520px) {
  .iconography-header {
    font-size: 2rem;
  }
}
@media (max-width: 385px) {
  .iconography-header {
    font-size: 1.5rem;
  }
}
@media (max-width: 300px) {
  .iconography-header {
    font-size: 1.2rem;
  }
}
</style>
