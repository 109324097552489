<template>
  <div>
    <img src="../assets/bio/DSC_1029.jpeg" />
    <p>
      Ekaterina Vandromme is a Russian born icon painter who lives in the UK.
      She first started painting icons in 2007 while temporarily living in
      Moscow, Russia. She studied the ancient Russo-Byzantine technique while
      there at Prosopon School of Iconology, and dedicated much attention to the
      exploration of the theological and philosophical aspects of icon painting
      and icon interpretation. Ekaterina has participated in several icon
      exhibitions held in the UK. She is very honoured to have been selected to
      become one of the students to follow the PSTA Iconography Diploma course.
      Ekaterina feels very enthusiastic about the new skills and techniques
      learned over the last three years, under the guidance of Aidan Hart and is
      looking forward to implement them in her future projects. While painting
      and in her everyday life she follows the religious traditions of the
      Russian Orthodox Church.
    </p>
  </div>
</template>

<script>
export default {
  name: "Bio",
};
</script>

<style scoped>
img {
  height: 60vh;
}
div {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 90px;
}
p {
  text-align: justify;
  letter-spacing: 1px;
  line-height: 30px;
  width: 50vw;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.4);
}
@media (max-width: 860px) {
  div {
    flex-direction: column;
    margin-top: 50px;
  }
  img {
    margin-bottom: 50px;
    height: 50vh;
  }
  p {
    width: 80vw;
    line-height: 1.3rem;
    text-align: center;
  }
}
</style>
