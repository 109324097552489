<template>
  <Header />
  <Bio />
  <Break />
  <Gallery />
  <Break />
  <Contact />
</template>

<script>
import Header from "./components/Header.vue";
import Bio from "./components/Bio.vue";
import Break from "./assets/Break.vue";
import Gallery from "./components/Gallery.vue";
import Contact from "./components/Contact.vue";

export default {
  name: "App",
  components: {
    Header,
    Bio,
    Break,
    Gallery,
    Contact,
  },
};
</script>

<style></style>
