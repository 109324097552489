<template>
  <h1>Contact</h1>
  <div class="container">
    <div v-if="contactNotice" class="contact-notice">
      <p>
        {{ contactNotice }}
      </p>
    </div>
    <form v-if="showContact" @submit.prevent="formSubmitHandler" novalidate>
      <label>Name*</label>
      <input type="text" v-model="name" name="name" />
      <label>Email*</label>
      <input type="email" v-model="email" name="email" />
      <label>Message*</label>
      <textarea
        name="message"
        v-model="message"
        cols="30"
        rows="5"
        maxlength="1000"
      >
      </textarea>
      <button>Send</button>
    </form>
    <div v-else>
      <h3>Message Sent Successfully!</h3>
      <p>Thanks for contacting me, I'll get back to you as soon as I can.</p>
    </div>
  </div>
</template>

<script>
import validator from "email-validator";

export default {
  name: "Contact",
  data() {
    return {
      email: "",
      message: "",
      name: "",
      showContact: true,
      contactNotice: "",
    };
  },
  methods: {
    formSubmitHandler() {
      if (!this.email) {
        this.contactNotice = "Please enter an email...";
        return;
      } else if (!validator.validate(this.email)) {
        this.contactNotice = "Please enter a valid email...";
      } else if (!this.message) {
        this.contactNotice = "Please enter a message...";
      } else if (!this.name) {
        this.contactNotice = "Please enter a name...";
      } else {
        const url = `https://us-central1-orthodox-icons.cloudfunctions.net/sendEmail/?emailFrom=${this.email}&message=${this.message}&name=${this.name}`;
        fetch(url);
        this.showContact = false;
        this.contactNotice = "";
      }
    },
  },
};
</script>

<style scoped>
input,
textarea {
  padding: 12px;
}
h3,
p {
  text-align: center;
  margin: 20px;
}
button {
  background-color: #272727;
  width: min-content;
  color: white;
  padding: 10px 40px;
  border: none;
  cursor: pointer;
  animation: none;
  height: min-content;
}
label,
button {
  margin-top: 30px;
  margin-bottom: 5px;
}
button:hover {
  animation: hover 0.1s ease-out;
  opacity: 0.8;
}
input,
textarea {
  background: #fafafa;
  border: 1px solid #ccc;
  border-radius: 2px;
}
textarea {
  resize: none;
}
form {
  display: flex;
  flex-direction: column;
  width: 65%;
}
label {
  font-size: 1.05rem;
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 45px;
}
.contact-notice {
  background-color: rgb(189, 13, 48);
  width: 65%;
}
.contact-notice > p {
  color: white;
  font-size: 1.1rem;
  text-align: center;
}
@keyframes hover {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.8;
  }
}
</style>
