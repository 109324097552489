<template>
  <header>
    <h1>Orthodox Icons</h1>
    <p>Ekaterina Vandromme</p>
    <p>Iconographer</p>
  </header>
</template>

<script>
export default {
  name: "Header",
};
</script>

<style scoped>
p {
  text-align: center;
  margin: 7px;
  letter-spacing: 0.277778em;
  color: #e7bd74;
  text-transform: uppercase;
  font-size: 0.8rem;
}
h1 {
  margin: 20px 20px 5px 20px;
}
</style>
